@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  height: 100%;
}

body{
  height: 100%;
  background-color: #1d140f;
}


@font-face {
  font-family: 'Bernstein-ExtraBold';
  src: url('/src/assets/fonts/Bernstein-ExtraBold Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: bold;
}

#id{
  height: 100%;
}